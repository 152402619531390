<template>
  <div>
    <el-dialog
        :title="title"
        :visible.sync="isOpenModal"
        :close-on-click-modal="false"
        width="1000px"
        @closed="closeModal" @open="openModal">
      <div style="width: 95%">
        <ch-form :render-option="option" :model="modalForm" :rules="rules" :splice="splice"
                 :props="{ labelWidth: '120px' , paddingRight: '10px'}"
                 ref="modalForm"
        >
          <template #shopSelect>
            <div class="shopSelect">
              <div class="select" >
                <el-input v-model="name" @keyup.enter.native="getLists" placeholder="请输入商品名称" />
                <ch-button type="ok" @click="getLists">搜索</ch-button>
              </div>
              <div class="shopList">
                <div class="shopItem" v-for="item in selectShopList" :key="item.id">
                  <img :src="item.coverUrl" alt="" />
                  <div style="flex: 1">{{item.goodsName}}</div>
                  <div v-if="!chooseShopIds.includes(item.id)">
                    <i @click="addShop(item)" class="el-icon-circle-plus-outline add"></i>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #changedShop>
            <div class="changedShop">
              <div class="shopList">
                <div class="shopItem" v-for="(item) in chooseShopList" :key="item.id">
                  <img :src="item.coverUrl" alt="" />
                  <div style="flex: 1">{{item.goodsName}}</div>
                  <div v-if="type !== 'watch'">
                    <i @click="removeShop(item)" class="el-icon-remove remove"></i>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ch-form>
      </div>
      <span slot="footer" class="flex_con" v-if="type !== 'watch'">
          <ch-button @click="closeModal" type="cancel">取消</ch-button>
          <ch-button
              @click="handleModal('modalForm')"
              :loading="isLoading"
              type="ok" >确定</ch-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      name: '',
      title: '',
      type: '',
      modalForm: {},
      isOpenModal: false,
      isLoading: false,
      rules: {
        name: [{required: true, message: "请输入名称", trigger: ["blur", "change"],}],
        validDays: [{required: true, message: "请选择有效期", trigger: ["blur", "change"],}],
        totalQuantity: [{required: true, message: "请输入数量", trigger: ["blur", "change"],}],
        amount: [{required: true, message: "请输入优惠金额", trigger: ["blur", "change"],}],
        goodsIdSet: [{required: true, message: "请选择绑定商品", trigger: ["blur", "change"],}],
      },
      chooseShopList: [],
      selectShopList: [],
      chooseShopIds: [],
    }
  },
  computed: {
    option: function () {
      if(this.type === 'watch'){
        return [
            {type: 'text', label: '优惠码名称：', prop: 'name',width: '200px'},
            {type: 'text', label: '有效期：', prop: 'validDays',sign: '天'},
            {type: 'text', label: '数量：', prop: 'totalQuantity',sign: '个'},
            {type: 'text', label: '剩余数量：', prop: 'remainingQuantity',sign: '个'},
            {type: 'text', label: '优惠金额：', prop: 'amount',sign: '元'},
            {type: 'text', label: '平台发码：', prop: 'isPlatformGenerated', yes: '是', no: '否'},
            {type: 'text', label: '一次使用：', prop:'isOneTimeUse', yes: '是', no: '否'},
            {type: 'slot', label: '绑定商品：', slotName: 'changedShop', },
        ]
      }{
        return [
          {type: 'input', label: '优惠码名称：', prop: 'name', placeholder: "请输入名称", },
          {type: 'select', label: '有效期：', prop: 'validDays', placeholder: '请选择有效期',
            name: "name", value: "value", option: [
              { name: "3日", value: 3 },
              { name: "7日", value: 7 },
              { name: "15日", value: 15 },
              { name: "30日", value: 30 },
            ]},
          {type: 'number', label: '数量：', prop: 'totalQuantity', placeholder: "请输入数量",precision:0},
          {type: 'number', label: '优惠金额：', prop: 'amount', placeholder: "请输入优惠金额",precision:2},
          {type: 'checkbox', label: '', prop:'isPlatformGenerated', labelWidth: '30px', option: [
              { label: 1,text: "平台发码",},
            ]},
          {type: 'checkbox', label: '', prop:'isOneTimeUse',  labelWidth: '30px', option: [
              { label: 1,text: "一次使用",},
            ]},
          {type: 'slot', label: '', slotName: 'shopSelect' , labelWidth: '30px'},
          {type: 'slot', label: '绑定商品：', slotName: 'changedShop', prop: 'goodsIdSet', },
        ]
      }
    },
    splice: function () {
      if(this.type === 'watch'){
        return [7]
      }else {
        return [6]
      }
    }
  },
  methods: {
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    openAdd(){
      this.title = '新增优惠码'
      this.type = 'add'
      this.modalForm = {
        name: '',
        validDays: null,
        totalQuantity: null,
        amount: null,
        isPlatformGenerated: 0,
        isOneTimeUse: 0,
        goodsIdSet: [],
      }
      this.isOpenModal = true
    },
    openEdit(id){
      this.title = '编辑优惠码'
      this.type = 'edit'
      this.getDetail(id)
    },
    openWatch(id){
      this.title = '查看优惠码'
      this.type = 'watch'
      this.getDetail(id)
    },
    // 选择商品
    addShop(item){
      this.chooseShopIds.push(item.id)
      this.modalForm.goodsIdSet = this.chooseShopIds
      this.chooseShopList.push(item)
      this.$refs.modalForm?.clearValidate('goodsIdSet')
    },
    removeShop(item){
      this.chooseShopIds = this.chooseShopIds.filter(id => id !== item.id)
      this.modalForm.goodsIdSet = this.chooseShopIds
      this.chooseShopList = this.chooseShopList.filter(shop => shop.id !== item.id)
    },
    getLists(){
      this.isTableLoading = true
      this.$curl.post("/hm/goods/list",{name:this.name,status:2}).then((res) => {
        this.selectShopList = res.rows
      }).finally(() => {this.isTableLoading = false});
    },
    // 详情
    getDetail(couponId){
      this.$curl.get("/hm/hmCoupon/detail/" + couponId).then((res) => {
        this.modalForm = res.data
        this.chooseShopList = res.data.goodsList
        this.chooseShopIds = res.data.goodsIdSet
        this.modalForm.isPlatformGenerated = this.modalForm.isPlatformGenerated === 1
        this.modalForm.isOneTimeUse = this.modalForm.isOneTimeUse === 1
      }).finally(() => {
        this.isOpenModal = true
      })
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.modalForm.isPlatformGenerated = this.modalForm.isPlatformGenerated ? 1 : 0
          this.modalForm.isOneTimeUse = this.modalForm.isOneTimeUse ? 1 : 0
          if(this.type === 'add'){
            this.$curl.post("/hm/hmCoupon/add", this.modalForm).then(() => {
              this.$message.success('新增成功');
              this.getListFunction?.()
              this.closeModal()
            }).finally(() => {
              this.isLoading = false
            })
          }else if(this.type === 'edit'){
            delete this.modalForm.creatorType
            delete this.modalForm.remainingQuantity
            delete this.modalForm.creatorId
            this.$curl.post("/hm/hmCoupon/edit", this.modalForm).then(() => {
              this.$message.success('编辑成功');
              this.getListFunction?.()
              this.closeModal()
            }).finally(() => {
              this.isLoading = false
            })
          }
        }
      })
    },
    // 关闭前清除数据
    closeModal() {
      this.isOpenModal = false
      this.modalForm = {
        isPlatformGenerated: [],
        isOneTimeUse: [],
      }
      this.selectShopList = []
      this.chooseShopList = []
      this.chooseShopIds = []
      this.name = ''
      this.type = ''
      this.title = ''
      this.isLoading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.shopSelect,.changedShop {
  line-height: normal;
  .select {
    display: flex;
    align-items: center;
    position: sticky;
  }
  .shopList {
    overflow-y: auto;
    max-height: 170px;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    line-height: normal;
    .shopItem {
      margin: 5px 5px 0 0;
      padding: 5px;
      max-width: 200px;
      white-space: break-spaces;
      height: fit-content;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      font-size: 10px;
      align-items: center;
      cursor: pointer;
    }
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }
}
.add {
  color: #3d7fff;font-size: 16px;margin-left: 10px;cursor: pointer;
}
.remove {
  color: #eb0a0a;font-size: 16px;margin-left: 10px;cursor: pointer;
}


.changedShop {
  position: absolute;
  top: 40px;
  left: -90px;
  width: 500px;
  min-height: 40px;
  max-height: 200px;
  overflow: auto;
}


::v-deep {
  .jsx_form_wrapper {
    .form_splice_wrapper .form_column_wrapper:first-child {
      flex: none;
      min-width: 345px;
    }
  }
}

</style>